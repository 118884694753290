import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AssessmentComponent} from "./assessment/assessment.component";

const routes:Routes = [{path: 'assessment/:id', component: AssessmentComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
