import {Injectable} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {HttpClient} from "@angular/common/http";
import {Test} from "./tests.service";
import { delay, retryWhen, flatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {interval} from "rxjs/index";
import {throwError} from "rxjs/index";

export interface Assessee {
  name: string;
  surname: string;
}

export interface Assessment {
  id: number;
  test: Test;
  assessee: Assessee;
  completed: boolean
}


@Injectable()
export class AssessmentsService {

  constructor(private http: HttpClient) {
  }


  getTest(id:number):Observable<Assessment> {
    return this.http.get<Assessment>(
      '/api/user/assessment/' + id);
  }

  postAnswers(assessment, data):Observable<any> {
    return this.http.post<any>('/api/response/assessment/' + assessment, data)
      .pipe(retryWhen(_ => {
        return interval(5000).pipe(
          flatMap(count => count == 10 ? throwError("Giving up") : of(count))
        )
      }))
  }

}
