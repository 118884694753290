import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'unansweredQuestion'})
export class UnansweredQuestion implements PipeTransform {
  transform(questions:any[]) {
    if (questions === null) {
      return questions;
    }
    return questions.filter(question => !question.answered);
  }
}
