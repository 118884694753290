import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AssessmentsService} from '../assessments.service';
import {Assessment} from '../assessments.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import {Question} from '../tests.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {
  public testData;
  public phase1 = false;
  public phase2 = false;
  public phase3 = false;
  myform: FormGroup;
  public currentQuestion;
  public questions;
  public postAnswersOngoing;
  public postAnswersDone;
  public assessmentId;
  public answers;
  public showValidationError = false;
  public title;
  public logo;
  public nrOfQuestion = 1;
  public preQuestions;
  public yesnoquestion: Question;
  private numberquestion: Question;
  private insightsquestion: Question;
  public language: string;

  constructor(private assessmentsService: AssessmentsService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private translateService: TranslateService) {

    this.myform = fb.group({
      name: [null, Validators.required],
      lastname: [null, Validators.required],
      numberquestion: [null, Validators.required],
      yesnoquestion: [null, Validators.required]
    });
  }

  private shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  ngOnInit() {
    this.phase1 = true;
    this.route.params.subscribe(params => {
      this.testData = this.assessmentsService.getTest(params['id']);
      this.testData.subscribe((testData: Assessment) => {
        this.language = testData.test.language;
        this.translateService.use(testData.test.language);
        this.title = testData.test.name;
        this.answers = testData.test.answers;
        this.questions = this.shuffle(testData.test.questions.filter((question) => question.type === 'question'));
        this.yesnoquestion = testData.test.questions.find((question) => question.type === 'yes-no');
        this.numberquestion = testData.test.questions.find((question) => question.type === 'number');
        this.insightsquestion = testData.test.questions.find((question) => question.type === 'insights');
        this.assessmentId = testData.id;
        this.currentQuestion = this.questions[0];
        this.logo = testData.test.logo;

        if (this.insightsquestion !== undefined) {
          this.myform = this.fb.group({
            name: [null, Validators.required],
            lastname: [null, Validators.required],
            numberquestion: [null, Validators.required],
            yesnoquestion: [null, Validators.required],
            insightsquestion_color1: [null, Validators.required],
            insightsquestion_color2: [null, Validators.required],
            insightsquestion_color3: [null, Validators.required],
            insightsquestion_color4: [null, Validators.required],
            insightsquestion_color5: [null, Validators.required],
          });
        }
      });
    });
  }


  submitForm() {
    if (this.myform.valid) {
      this.phase1 = false;
      this.phase2 = true;
    } else {
      this.showValidationError = true;
    }
  }

  onAnswer(question, answer) {
    question.answer = answer;
    question.answered = true;
    if (this.questions.filter((question) => !question.answered).length > 0) {
      this.currentQuestion = this.questions.filter((question) => !question.answered)[0];
      this.nrOfQuestion = this.questions.filter((question) => !!question.answered).length + 1;
    } else {
      this.phase2 = false;
      this.phase3 = true;
      this.postAnswers();
    }
  }

  back() {
    const numberOfAnsweredQuestions = this.questions.filter((question) => !!question.answered).length;
    const lastAnsweredQuestion = this.questions[numberOfAnsweredQuestions - 1];
    lastAnsweredQuestion.answer = undefined;
    lastAnsweredQuestion.answered = false;

    this.currentQuestion = this.questions.filter((question) => !question.answered)[0];
    this.nrOfQuestion = this.questions.filter((question) => !!question.answered).length + 1;
  }

  postAnswers() {
    this.postAnswersOngoing = true;
    let prequestions = [{
      ...this.yesnoquestion,
      answertext: this.myform.value.yesnoquestion
    }, {
      ...this.numberquestion,
      answertext: this.myform.value.numberquestion
    }];
    if (this.insightsquestion !== undefined) {
      prequestions = [...prequestions, {
        ...this.insightsquestion,
        answertext: `${this.myform.value.insightsquestion_color1}-${this.myform.value.insightsquestion_color2}-${this.myform.value.insightsquestion_color3}-${this.myform.value.insightsquestion_color4}-${this.myform.value.insightsquestion_color5}`
      }];
    }
    this.assessmentsService.postAnswers(this.assessmentId, {...this.myform.value, ...{questions: this.questions}, ...{prequestions: prequestions}}).subscribe(() => {
      this.postAnswersOngoing = false;
      this.postAnswersDone = true;
    });
  }


}
